@import '~antd/dist/antd.css';

#root {
    min-height: 100vh;
}

.logo {
    float: left;
    width: 120px;
    height: 31px;
    margin: 0;
    position: relative;
}

.logo img {
    position: absolute;
    top: 50%;
    left: 50%;
    height: 60%;
    transform: translate(-50%, -50%);
    fill: white;
}